import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { MenuItem } from 'primeng/api';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrl: './docs.component.scss'
})
export class DocsComponent implements OnInit{

  defaultFilename = 'README.md';

  filename = this.defaultFilename;

  breadcrumbs : MenuItem[] = [];

  constructor(private route:ActivatedRoute){}

  async ngOnInit() {

    let params = await firstValueFrom(this.route.paramMap);

    let folder = params.get('folder');
    if(folder){
      // Default to the folder name plus the index file
      this.filename = folder + '/' + this.defaultFilename; 

      let queryPath : string = this.route.snapshot.queryParams['path'];

      if(queryPath){

        let queryParts = queryPath.split('/');
        // If there is no filename (*.md) then append default filename
        if(!/.*?\.md/i.test(queryParts[queryParts.length-1])){
          queryParts.push(this.defaultFilename);
        }
        this.filename = [folder, ...queryParts].join('/');

        // Remove any directory operators for breadcrumbs
        let pathParts = queryPath.split('/').filter(p=> !['.','..'].includes(p));

        this.breadcrumbs =[
          { label: _.upperFirst(folder), url: `/docs/${folder}`, target: '_self'}, 
          ...pathParts.map( (p,i) => {
            if(i < pathParts.length - 1){
              return { 
                label: _.upperFirst(p), 
                url: `/docs/${folder}?path=` + pathParts.slice(0,i+1).join('/'),
                target: '_self'
              }
            }
            return {label:p};
        })];
    }
  }

}
}
